import { removeServicesAccountHeader, removeXVCMAuthorizationtHeader } from 'shared/Network/Services';
import { DownloadFileActionTypes } from '../ReduxConstants';

export const initialState = {
    downloadedFile: null,
    showError:false,
    isLoading: false,
};

function downloadFileReducer(state = initialState, action = { payload: undefined }) {
    switch (action.type) {
        case DownloadFileActionTypes.GET_DOCUMENT: {
            return {
                ...state,
                isLoading: true,
                showError: false
            };
        }
        case DownloadFileActionTypes.GET_DOCUMENT_SUCCESS: {
            removeServicesAccountHeader();
            removeXVCMAuthorizationtHeader();
            const downloadedFile = action.payload;
            return {
                ...state,
                downloadedFile,
                showError: false,
                isLoading: false,
            };
        }

        case DownloadFileActionTypes.GET_DOCUMENT_FORM_URL_SUCCESS: {
            removeServicesAccountHeader();
            removeXVCMAuthorizationtHeader();
            const downloadedFile = action.payload;
            return {
                ...state,
                downloadedFile,
                showError: false,
                isLoading: false,
            };
        }

        case DownloadFileActionTypes.DOWNLOAD_COMPLETED: {
            return {
                ...state,
                downloadedFile: null,
                showError: false
            };
        }
        case DownloadFileActionTypes.GET_DOCUMENT_ERROR: {
            removeServicesAccountHeader()
            return {
                ...state,
                downloadedFile: null,
                showError: true,
                isLoading: false,
            };
        }

        case DownloadFileActionTypes.GET_DOCUMENT_FORM_URL_ERROR : {
            removeServicesAccountHeader()
            return {
                ...state,
                downloadedFile: null,
                showError: true,
                isLoading: false,
            };
        }
        default:
            return state;
    }
}

export default downloadFileReducer;
