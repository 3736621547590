/* eslint-disable no-nested-ternary */
import * as DownloadFileConstants from '../ReduxConstants/DownloadFileConstants';
import Config from 'env/Config';
import makeApiRequest from '../Network/ApiInterface';

const {
    DOWNLOAD_COMPLETED,
    GET_DOCUMENT_ERROR,
    GET_DOCUMENT_SUCCESS,
    GET_DOCUMENT,
    UPDATE_DOCUMENT_META_DATA,
    UPDATE_DOCUMENT_META_DATA_SUCCESS,
    UPDATE_DOCUMENT_META_DATA_FAILURE,
    GET_DOCUMENT_FORM_URL,
    GET_DOCUMENT_FORM_URL_SUCCESS,
    GET_DOCUMENT_FORM_URL_ERROR

} = DownloadFileConstants;

export const generateQueryString = linkParams => {
    const { alternateDocumentId, docCategory, documentSource, nodeId } = linkParams;
    const isAlternateDocumentId = Boolean(alternateDocumentId);

    let productId = '';
    const source = documentSource.toLowerCase() || 'alfresco';
    const docId = alternateDocumentId || nodeId;
    const category = docCategory.toUpperCase();

    if (isAlternateDocumentId && source === 'broadridge') {
        productId = (category === 'STATEMENT') ? 'STMT'
            : (category === 'CONFIRM' || category === 'CONFIRMATION') ? 'CDLY'
            : (category === 'TAX' || category === 'TAX DOCUMENTS') ? 'YPCT'
            : (category === 'ACCOUNT_SERVICING') ? 'LTRS'
            : (category === 'ACCOUNT_MAINTENANCE') ? 'LTRS'
            : '';
    }

    return productId === ''
        ? `?docid=${docId}&download=true&source=${source}&inline=true`
        : `?docid=${docId}&download=true&source=${source}&productid=${productId}&inline=true`;
};

export const generateQueryStringForMobile = (linkParams) => {
    const baseUrl = Config.API_BASE_URL.DOCUMENT_CENTER;
    const pathUrl = Config.getDocument;
    const queryString = generateQueryString(linkParams);
    return `${baseUrl}${pathUrl}${queryString}`;
};

export const updateDocumentMetaData = (payload) => async dispatch => {
    const env = '';
    const { nodeId, readIndicator } = payload;

    const newPayload = {
        contentType: 'vcm:document',
        vcmDocMetadata: {
            nodeId,
            readIndicator,
        },
    };

    dispatch({ type: UPDATE_DOCUMENT_META_DATA });

    makeApiRequest(
        UPDATE_DOCUMENT_META_DATA,
        newPayload,
        env,
        response => {
            if (response.data) {
                dispatch({
                    type: UPDATE_DOCUMENT_META_DATA_SUCCESS,
                    res: response.data,
                });
            }
        },
        error => {
            dispatch({
                type: UPDATE_DOCUMENT_META_DATA_FAILURE,
                payload: error,
            });
        },
    );
};

const getDocument = linkParams => async dispatch => {
    const env = '';
    const { token, downloadUrl } = linkParams;
    // const queryString = generateQueryString(linkParams);

    dispatch({ type: GET_DOCUMENT });
    const {alternateDocumentId,nodeId} = linkParams

    // Always need to pass nodeID only for direct document API
    //const docId = alternateDocumentId || nodeId;
    const docId = nodeId;

    makeApiRequest(
        GET_DOCUMENT,
        { queryString: '', downloadUrl, token,docId },
        env,
        response => {
            if (response.data) {
                dispatch({
                    type: GET_DOCUMENT_SUCCESS,
                    payload: response.data,
                });
            }
        },
        error => {
            dispatch({
                type: GET_DOCUMENT_ERROR,
                payload: error,
            });
        },
    );
};



const getDocumentFormUrl = formID => async dispatch => {
    const env = '';

    dispatch({ type: GET_DOCUMENT_FORM_URL });

    makeApiRequest(
        GET_DOCUMENT_FORM_URL,
        {formID:formID},
        env,
        response => {
            if (response.data) {
                dispatch({
                    type: GET_DOCUMENT_FORM_URL_SUCCESS,
                    payload: response.data,
                });
            }
        },
        error => {
            dispatch({
                type: GET_DOCUMENT_FORM_URL_ERROR,
                payload: error,
            });
        },
    );
};

const downloadCompleted = () => dispatch => {
    dispatch({
        type: DOWNLOAD_COMPLETED,
        payload: true,
    });
};

export { getDocument, downloadCompleted,getDocumentFormUrl };
export default getDocument;
