export const GET_DOCUMENT = 'GET_DOCUMENT';
export const GET_DOCUMENT_SUCCESS = 'GET_DOCUMENT_SUCCESS';
export const GET_DOCUMENT_ERROR = 'GET_DOCUMENT_ERROR';
export const DOWNLOAD_COMPLETED = 'DOWNLOAD_COMPLETED';

export const UPDATE_DOCUMENT_META_DATA = 'UPDATE_DOCUMENT_META_DATA';
export const UPDATE_DOCUMENT_META_DATA_SUCCESS = 'UPDATE_DOCUMENT_META_DATA_SUCCESS';
export const UPDATE_DOCUMENT_META_DATA_FAILURE = 'UPDATE_DOCUMENT_META_DATA_FAILURE';

export const GET_DOCUMENT_FORM_URL = 'GET_DOCUMENT_FORM_URL';
export const GET_DOCUMENT_FORM_URL_SUCCESS = 'GET_DOCUMENT_FORM_URL_SUCCESS';
export const GET_DOCUMENT_FORM_URL_ERROR = 'GET_DOCUMENT_FORM_URL_ERROR';
