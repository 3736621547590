import {
    AccessFormsActionTypes,
    DocumentCenterActionTypes,
    DownloadFileActionTypes,
} from '../ReduxConstants';
import * as ActionTypes from '../ReduxConstants/ServiceActionConstants';
import ApiEventHub from './ApiEventHub';
// eslint-disable-next-line
import Config from 'env/Config';
// eslint-disable-next-line
import documentCenterMock from './DocumentCenterMockData';
import { searchDocument, post, getDocument, getAccessForms, getFormsById, setMSRDocumentHeaders,setMSRTransferDocumentHeaders,addAPIVersionHeader, getDocumentId, removeAPIVersionHeader, setMSRAuthHeaders,getDocumentFormUrl } from './Services';
import { getSessionValues } from 'shared/Helpers/Utils';
import envConfig from 'env/EnvConfig';
const DocumentCenterApiEvents = () => {

    const createDocumentEndPointUrl = data => {
        if (data.payload?.downloadUrl?.length > 0) {
            return data.payload.downloadUrl;
        }
        if (data.payload?.queryString) {
            return `${data.endPointURL.DOCUMENT_CENTER}${Config.getDocument}${data.payload?.queryString}`;
        }
        return `${data.endPointURL.DOCUMENT_CENTER}${Config.getDocument}`;
    };

    ApiEventHub.on(AccessFormsActionTypes.GET_ACCESS_FORMS, data => {
        getAccessForms(
            Config.getAccessForms,
            data.payload,
            data.endPointURL.DOCUMENT_CENTER,
            data.successCallBack,
            data.errorCallback,
            Config.serviceAccount,
        );
    });

    ApiEventHub.on(AccessFormsActionTypes.GET_FORMS_BY_ID, data => {
        getFormsById(
            Config.getFormsById,
            data.payload,
            data.endPointURL.DOCUMENT_CENTER,
            data.successCallBack,
            data.errorCallback,
            Config.serviceAccount,
        );
    });

    ApiEventHub.on(DocumentCenterActionTypes.GET_DOCUMENT_LIST, data => {
        documentCenterMock().mock(() => {
            post(
                Config.searchDocument,
                data.payload,
                data.endPointURL.DOCUMENT_CENTER,
                data.successCallBack,
                data.errorCallback,
            );
        }, data.payload);
    });

    ApiEventHub.on(DownloadFileActionTypes.GET_DOCUMENT, data => {

        const docId = data?.payload?.docId
        const url = `${envConfig.GET_DOCUMENT_API_URL}/${docId}`;
        addAPIVersionHeader('2023-06-30');
        getDocument(url, data.successCallBack, data.errorCallback, Config.serviceAccount, data.payload,true);
    });

    ApiEventHub.on(DownloadFileActionTypes.GET_DOCUMENT_FORM_URL , data => {

        const url = `${Config.API_BASE_URL.formsFileDownload}/${data.payload.formID}`;
        getDocumentFormUrl(url, data.successCallBack, data.errorCallback, Config.serviceAccount, data.payload,false);
    });
    
    ApiEventHub.on(DocumentCenterActionTypes.GET_USER_DOCUMENT_LIST, data => {
        
        const params = getSessionValues();
        const apiKey = Config.dashboardPortfolioSummaryKey;
        setMSRDocumentHeaders(params.accesstoken, apiKey, params?.msrid);
        searchDocument(
            Config.getUserDocumentList,
            data.payload,
            data.endPointURL.DOCUMENT_CENTER,
            data.successCallBack,
            data.errorCallback,
            Config.serviceAccount,
        );
    });

    ApiEventHub.on(DocumentCenterActionTypes.GET_TRANSFER_DOCUMENT_LIST, data => {
        
        const params = getSessionValues();
        const apiKey = Config.dashboardPortfolioSummaryKey;
        setMSRTransferDocumentHeaders(params.accesstoken, apiKey, params?.msrid);
        addAPIVersionHeader('2023-06-30');
        searchDocument(
            Config.getTransferDocumentList,
            data.payload,
            data.endPointURL.DOCUMENT_MANAGER,
            data.successCallBack,
            data.errorCallback,
            Config.serviceAccount,
        );
        
    });

    ApiEventHub.on(DownloadFileActionTypes.UPDATE_DOCUMENT_META_DATA, data => {
        post(
            Config.updateDocumentMetaData,
            data.payload,
            data.endPointURL.DOCUMENT_CENTER,
            data.successCallBack,
            data.errorCallback,
            {
                headers: {
                    'service-account': Config.serviceAccount,
                }
            }
        );
    });


    ApiEventHub.on(ActionTypes.UPLOAD_DOCUMENT_CONFIRMATION, data => {
        removeAPIVersionHeader();  
        getDocumentId(
            Config.updateDocument,
            data.payload,
            data.endPointURL.getPortfolioPlannerRedirectionData,
            data.successCallBack,
            data.errorCallback,
        );
    });
};

export default DocumentCenterApiEvents;
