import envConfig from './EnvConfig.js';


// let API_SUB_DOMAIN; let APP_API_KEY; let METADATA_API_KEY; let USPS_API_KEY;
const API_SUB_DOMAIN = envConfig.API_SUB_DOMAIN;
const APP_API_KEY = envConfig.API_KEY;
const METADATA_API_KEY = envConfig.METADATA_API_KEY;
const USPS_API_KEY = envConfig.USPS_API_KEY;
const DASHBOARD_PORTFOLIO_SUMMARY_API_KEY = envConfig.DASHBOARD_PORTFOLIO_SUMMARY_API_KEY;
const ASCENSUS_API_KEY = envConfig.ASCENSUS_API_KEY;
const LOGGER_API_KEY = envConfig.LOGGER_API_KEY;
const CUSTOMER_MANAGEMENT_API_KEY = envConfig.CUSTOMER_MANAGEMENT_API_KEY;
const LIVE_CHAT_API_KEY = envConfig.LIVE_CHAT_API_KEY;
const ACCOUNT_MAP_SUB_DOMAIN_API = envConfig.ACCOUNT_MAP_SUB_DOMAIN_API;
const ACCOUNT_MAP_API_KEY = envConfig.ACCOUNT_MAP_API_KEY;
const VERIFY_CUSTOMER_API_KEY = envConfig.VERIFY_CUSTOMER_API_KEY;
const GIACT_COGNITO_API = envConfig.COGNITO_CUSTOMER_GIACT_VERIFICATION;
const API_SUB_DOMAIN_MSR = envConfig.API_SUB_DOMAIN_MSR;
const ACCOUNT_OPTIONS_API_KEY = envConfig.ACCOUNT_OPTIONS_API_KEY;
const CUSTOMER_PROFILE_API_KEY = envConfig.CUSTOMER_PROFILE_API_KEY;
const TRANSACTION_API_KEY = envConfig.TRANSACTION_API_KEY;
const MEMBER_PROFILE_API = envConfig.MEMBER_PROFILE_API;
const STATEMENT_API_KEY = envConfig.STATEMENT_API_KEY;
const MEMBER_PREFERENCES_KEY = envConfig.MEMBER_PREFERENCES_KEY;
const SECURITY_API_KEY = envConfig.SECURITY_API_KEY;





const Config = {

    UiConfig : {
        CONTENT_URL: {
            TERMS_PDF_USA_PATRIOT_ACT: envConfig.TERMS_PDF_USA_PATRIOT_ACT,
            TERMS_PDF_TRUSTED_CONTACT_PERSON_DISCLOSER:'https://stage.vcm.com/content/dam/vcm/policies/victory-privacy-statement.pdf',
            ENV_URL: 'https://qa.investor.vcm.com'
        },
    
    },

    API_BASE_URL: {
        // sign up
        devEnvironment: `${API_SUB_DOMAIN}/`,
        BasePath:`${API_SUB_DOMAIN_MSR}/`,
        MOCK: `${API_SUB_DOMAIN}/`,
        LOOKUP: `${API_SUB_DOMAIN}/`,
        USPS: `${API_SUB_DOMAIN}/`,
        GIACT_LOCAL: `${API_SUB_DOMAIN}/giact/local/`,
        UPLOAD_IMAGE: 'https://y3njkbvto8.execute-api.us-east-2.amazonaws.com/Dev/',
        RETRIVE_IMAGE: 'https://fgh14g8bp8.execute-api.us-east-2.amazonaws.com/Dev/',
        DASH_BOARD: `${API_SUB_DOMAIN}/trance/msr/`,
        USER_MANAGEMENT: 'https://85b64w7q49.execute-api.us-east-2.amazonaws.com/prod/',

        MESSAGE_CENTER: `${API_SUB_DOMAIN}/messagecenter/`,
        DOCUMENT_CENTER: `${API_SUB_DOMAIN}/documentcenter/`,
        DOCUMENT_MANAGER: `${API_SUB_DOMAIN}/document-manager/`,
        CHAT_API: `${API_SUB_DOMAIN}/livechat/`,

        TRANSACTION: `${API_SUB_DOMAIN_MSR}/trance/msr/`,
        SAVED_APPLICATIONS: `${API_SUB_DOMAIN}/txnstore/msr/application/`,
        getPurchaseWireTransferInfo: 'https://sykdysa7wk.execute-api.us-east-2.amazonaws.com/dev/',
        // getFundListForLiquidation: 'https://sykdysa7wk.execute-api.us-east-2.amazonaws.com/dev/',

        swptransaction: 'https://484r0kbyd8.execute-api.us-east-2.amazonaws.com/Mock/',
        updatefunddetails: 'https://osbgk3fp8l.execute-api.us-east-2.amazonaws.com/mock/',
        addintrestedparties: 'https://taefkuqryc.execute-api.us-east-2.amazonaws.com/Mock/',
        updateintrestedparties: 'https://dzx0mm7e97.execute-api.us-east-2.amazonaws.com/Mock/',
        deleteintrestedparties: 'https://osk2716mi0.execute-api.us-east-2.amazonaws.com/Mock/',
        getintrestedparties: 'https://oavjv657a8.execute-api.us-east-2.amazonaws.com/Mock/',
        getprimarybeneficiary: 'https://pjkrd6udk6.execute-api.us-east-2.amazonaws.com/Mock/',
        addprimarybeneficiary: 'https://lxu89otvu1.execute-api.us-east-2.amazonaws.com/Mock/',
        updateprimarybeneficiary: 'https://qk89lwmib4.execute-api.us-east-2.amazonaws.com/Mock/',
        getlastupdateddateofbeneficiary: 'https://mv68s3muw9.execute-api.us-east-2.amazonaws.com/Mock/',
        getcontingentbeneficiary: 'https://r4cw4x0974.execute-api.us-east-2.amazonaws.com/Mock/',
        addcontingentbeneficiary: 'https://a5pr5rq2pc.execute-api.us-east-2.amazonaws.com/Mock/',
        updatecontingentbeneficiary: 'https://rtn66jo5nl.execute-api.us-east-2.amazonaws.com/Mock/',
        getNewsAndCommentary: 'https://newsapi.org/v2/',
        customerManagementEndPoints: `${API_SUB_DOMAIN}/`,
        accountManagementEndPoints: `${API_SUB_DOMAIN}/`,

        validatebankaccount: 'https://v67smbstul.execute-api.us-east-2.amazonaws.com/Dev',
        deletebankaccount: 'https://qlsiqs9kwa.execute-api.us-east-2.amazonaws.com/Mock/',

        dashboard: `${API_SUB_DOMAIN}/dashboard/`,

        
        getDashboardSavedItems: `${API_SUB_DOMAIN}/txnstore/`,

        dashboardAscensusRedirection: `${API_SUB_DOMAIN}/`,
        singleSignOnRedirection: `${ACCOUNT_MAP_SUB_DOMAIN_API}/`,
        getCheckDeliveryAddress: 'https://duyvjflsji.execute-api.us-east-2.amazonaws.com/test/',
        //getCompositeData: 'https://dev.memberapi.vcm.com/',

        // MSR Web Flow Configuration Starts

        msrAccountSummary: `${API_SUB_DOMAIN_MSR}/dashboard/msr/`,
        msrgetDashboardSavedItems: `${API_SUB_DOMAIN}/txnstore/msr/`,
        newDashboard: `${API_SUB_DOMAIN}/dashboard/v1/`,
        msrnewDashboard: `${API_SUB_DOMAIN}/dashboard/msr/v1/`,
        // dashbordPortfoli : `${API_SUB_DOMAIN}/dashboard/v1/`,
        getPortfolioPlannerRedirectionData: `${API_SUB_DOMAIN}/portfolioplanner/`,

        msrUserProfile: `${API_SUB_DOMAIN_MSR}/profilemsr/`,
        newMsrUserProfileView: `${API_SUB_DOMAIN_MSR}/users/msr/`,
        msrAscensusAmt: 'https://dsv8ri2g1j.execute-api.us-east-2.amazonaws.com/dev/',
        msrBasicProfileInfo: `${API_SUB_DOMAIN_MSR}/memberprofilemsr/`,
        msrGetTransaction: `${API_SUB_DOMAIN_MSR}/trance/msr/`,
        msrTransactionHistory: `${API_SUB_DOMAIN_MSR}/trance/msr/`,
       // msrBankAccountList: `${API_SUB_DOMAIN_MSR}/transactionmsr/`,
        msrBankAccountList: `${API_SUB_DOMAIN_MSR}/trance/msr/`,
        msrCheckBookInfo: `${API_SUB_DOMAIN}/account-options/msr/`,
        msrDividendInfo: `${API_SUB_DOMAIN}/account-options/msr/`,
        msrManageBenificiary: `${API_SUB_DOMAIN_MSR}/accountoptionmsr/`,
        msrGetBenificiary: `${API_SUB_DOMAIN}/account-options/msr/`,
        msrStatementSettingAPI: `${API_SUB_DOMAIN}/account-options/msr/`,
        msrMemberPreferenceAPI: `${API_SUB_DOMAIN_MSR}/memberpreferencemsr/`,
        msrSecurityListAPI: `${API_SUB_DOMAIN_MSR}/usersmsr/`,
        msrAccountOptions: `${API_SUB_DOMAIN_MSR}/accountoptionmsr/`,
        getMsrAccountOptions:`${API_SUB_DOMAIN_MSR}/account-options/msr/`,
        msrTransactions: `${API_SUB_DOMAIN_MSR}/trance/msr/`,
        msrEnableFeature: `${API_SUB_DOMAIN_MSR}/`,
        msrBrokerageTransactions: `${API_SUB_DOMAIN_MSR}/trance/`,
        // MSR Web Flow Configuration Ends

        getCompositeData: `${API_SUB_DOMAIN_MSR}/`,
        getMarstoneRedirectionData: `${API_SUB_DOMAIN}/marstone/`,
        getDefaultAccountNickName: `${ACCOUNT_MAP_SUB_DOMAIN_API}/`,
        validateCustomerInfo: `${API_SUB_DOMAIN}/giact/`,
        getCognitoForGIACTVerification: `${GIACT_COGNITO_API}/`,
        getIRAContributionYearValues : `${API_SUB_DOMAIN_MSR}/trance/msr/getIRAContributions/`,
        investorApi: `${ACCOUNT_MAP_SUB_DOMAIN_API}/`,
        getUploadSessionBaseUrl: `${API_SUB_DOMAIN}/documentcenter/document-api/`,
        uploadSecureMessageFile: `${ACCOUNT_MAP_SUB_DOMAIN_API}/`,
        formsFileDownload: `${API_SUB_DOMAIN_MSR}/services/forms/pre-fill`
    },

    getmasterdata: 'metadata',
    getmasterlookupdata: 'metadata/applicationmetadata/lookup',
    getCompositeData: 'referencedata/msr/applicationmetadata/lookup',
    addBank: 'banks/bankaccount/add',
    getfunds: 'fund/list', // For both getFunds & filterFunds
    getfunddetails: 'search/products/FUND', // 'fund/details', // For both compareFunds & fundDetails
    // openaccountInd: 'account/add', // For  Individual
    openaccountInd: 'OpenAccountBulk',
    openaccountIRA: 'account/add/ira', // For  IRA
    openaccountJoint: 'account/add/joint', // For Joint
    openaccountUTMA: 'account/add/ugma-utma', // For UTMA
    saveappdata: 'account/save',
    getsaveddata: 'account/savedform',
    addressLookUp: 'usps/msr/verify/address',
    multipleAddressLookUp: 'usps/verify/addresses',
    zipCodeLookUp: 'usps/lookup/zip',
    cityStateLookUp: 'usps/lookup/cityState',
    bankAccVerification: 'banks/bankaccount/verify',
    customerVerfification: 'verify/customerBankAccount',
    uploadImage: '',

    swptransaction: 'api-v1-swptransaction',
    updatefunddetails: 'api-v1-updatefunddetails',
    addintrestedparties: 'api-v1-addintrestedparties',
    updateintrestedparties: 'api-v1-updateintrestedparties',
    deleteintrestedparties: 'api-v1-deleteintrestedparties',
    getintrestedparties: 'api-v1-getintrestedparties',
    getprimarybeneficiary: 'api-v1-getprimarybeneficiary',
    addprimarybeneficiary: 'api-v1-addprimarybeneficiary',
    updateprimarybeneficiary: 'api-v1-updateprimarybeneficiary',
    getlastupdateddateofbeneficiary: 'api-v1-getlastupdateddateofbeneficiary',
    getcontingentbeneficiary: 'api-v1-getcontingentbeneficiary',
    addcontingentbeneficiary: 'api-v1-addcontingentbeneficiary',
    updatecontingentbeneficiary: 'api-v1-updatecontingentbeneficiary',

    // Transactions
    // purchasefunds: 'account/transaction/purchase',
    purchasefunds: 'transactions/purchase',
    getpendingtransactionhistory: 'account/transaction/pending',
    //cancelTransaction: 'account/transaction/cancel',
    cancelTransaction: 'trance/msr/transaction/cancel',
    liquidationtransaction: 'transactions/liquidate',
    exchagetransaction: 'exchange',
    // getLiquidationTradingLimit: 'liquidations/dailyLimit',
    getLiquidationTradingLimit: 'liquidations/validate',

    // Bank account
    //  getbankaccounts:"api-v1-bank-withcrm",
    // getbankaccounts: 'account/bank',
    getbankaccounts: 'bankaccountlist',
    getBrokereageBankaccounts: 'brokerage/bankaccountlist',
    getBrokereageBankaccountsVerification: 'msr/brokerage/application/save/bankAccountInfo',
    validatebankaccount: '',
    deletebankaccount: 'api-v1-bank-withcrm/delete',
    addbankaccount: 'account/bank/add',
    // addNewBankAccount: 'banks/bankaccount/add',
    // ACCOUNT OPTIONS
    getaccountlist: 'accountlist',
    getAIPAccountList: 'rt-bank-to-fund-for-add',
    getPADDetails: 'account-options/rt-bank-to-fund-accounts',
    getFundAccountByPad: 'account-options/rt-bank-to-fund-account-by-pad',
    addPADDetails: 'add-rt-bank-to-fund-account',
    addSWPDEtails: 'account-options/add-new-swp-trans',
    updatePADDetails: 'update-rt-bank-to-fund-account',
    skippadtransaction: 'transactions/pad/skip',
    deleteAutoInvestPlan: 'delete-rt-bank-to-fund-account',
    deletepadtransaction: 'account-options/delete-rt-bank-to-fund-account',
    getSWPDetails: 'account-options/swp-transactions',
    getSWPAccountList: 'swp-accounts',
    getSWPById: 'account-options/get-single-swp',
    skipSWP: 'transactions/swp/skip',
    deleteSWP: 'account-options/delete-swp-plan',
    addSWP: 'transactions/swp/add',
    updateSWP: 'transactions/swp/update',
    updateSWPDetails: 'account-options/update-swp',

    getNewsAndCommentary:
        'top-headlines?country=us&category=business&apiKey=66de37b0c1c84396b3258be2878c47f6&pageSize=6',

    // Profile Setting - Live

    getMemberPreferences: 'delivery-preference/getMemberPreferences/',
    getMemberMSRPreferences: 'delivery-preference/msr/getMemberPreferences',
    updateMemberPreferences: 'delivery-preference/updateMemberPreferences/',

    getProfile: 'members/msr/member/profile',
    updateProfile: 'members/member/profile/update',
    updateProfile_v1:'members/msr/v1/member/profile/update ',
    getAssociation: 'members/msr/member/association',
    updateAssociation: 'members/member/association/update',
    profileAddressLookUp: 'members/member/profile/address/lookup',
    getAccountLevelAddress: 'members/account/address',
    updateAccountLevelAddress: 'members/account/address/update',
    verifyEmail: 'members/verify/email',
    otpConfirmEmail: 'members/verify/emailOTPConfirm',

    // New Endpoint change on 29/04/2020

    validateEmail: 'users/user/email/verify',
    sendEmailLink: 'users/guest/userinfo',
    verifiedEmailLink: 'users/guest/uuid/signup/verify',
    signUp: 'users/guest/create',

    // Member Sign Up

    directMemberID: 'users/member/memberid/verify',
    directSendEmailOTP: 'users/member/otp/signup/email',
    directVerifyOTP: 'users/member/otp/signup/enter',
    directSendPhoneOTP: 'users/member/otp/signup/send',
    directStatusofVerifyOTP: 'users/member/otp/signup/confirm',
    directSignUp: 'users/member/signup/create',
    directVerifyMember: 'users/member/msr/verifyMember',

    // frist time sign in

    sendOTP: 'users/member/otp/send',
    verifyOTP: 'users/member/otp/confirm',
    saveSecurityQuestions: 'users/member/securityquestions/save',
    documentPreference: 'users/member/signin/firsttimesignin',
    // Recover OnlineID

    recoverOnlineID: 'users/user/onlineid/recover',
    recoverOnlineIDType: 'users/user/verify',
    // Recover Password For Guest

    recoverPasswordGuest: 'users/guest/password/recover',
    recoverPwrdGuestEmailVerify: 'users/guest/uuid/password/verify',
    recoverPassword: 'users/member/password/reset',
    resetPasswordForGuest: 'users/guest/password/reset',

    // Recover Password For Customer

    recoverPasswordCustomer: 'users/member/password/recover',
    recoverPasswordMemberOrGuest: 'users/user/identify',
    verifyIVR: 'users/member/otp/verify',

    // Validate Securiy Questions

    validateSecurityQuestions: 'users/member/securityquestions/validate',

    // Manage Security

    getSecurityQuestions: 'users/member/securityquestions/list',
    getChangeSignInMethod: 'users/member/signin/signinmethod',
    setChangeSignInMethod: 'users/member/signin/signinmethod',
    getCustomerProfile: 'users/user/profile/view',
    // getCustomerProfile : "prod/member",
    changePassword: 'users/user/password/change',
    resendOTPCustomer: 'users/member/otp/resend',
    loginAttempts: 'users/user/loginattempts',
    signUpOnlineVerify: 'users/user/onlineid/verify',

    loginWithBiometric: 'ECLM0043',
    logout: 'ECLM0023',
    Verion: '2.1.15',
    buildNumber: '5424',
    date: '21 Feb 2019',
    year: '2019',

    // Transaction History
    transactionHist: 'accountholder/transaction/getTransHistory',

    // Refresh Token time
    refreshAfter: 600000,
    refreshQuick: 60000,

    // Message center
    getUnreadMessageCount: 'message-center/messages/count',
    getMessages: 'message-center/messages',
    getTopics: 'message-center/topics',
    getAccounts: 'message-center/accounts',
    deleteMessages: 'message-center/messages',
    setReadUnreadToMessages: 'message-center/messages/read',
    // sendSecureMessage: 'message-center/accounts',
    sendSecureMessage: 'message-center/messages/secured',
    getDashboardWidgetMessages: 'message-center/msgpanel',

    // Dividents
    getAccountsList: 'account-options/dividends-info',
    updateDividends: 'account-options/dividends-info',

    // Beneficiary
    getBeneficiaries: 'account-options/beneficiaries',
    getBeneficiariesMasterRegs: 'account-options/beneficiaries/master-regs',
    addBeneficiaries: 'account-options/beneficiaries',
   
    // Statement Settings
    openedAccountsPreferences: 'account-options/statement-settings',

    // RMD
    getRMDAccounts: 'account-options/get-rmd-accounts',
    rmdTransactions: 'account-options/get-rmd-transactions',
    addRMDTransaction: 'account-options/add-rmd-transaction',
    deleteRMDTransaction: 'account-options/delete-rmd-transaction',

    // Checkbook
    getCheckBookInfo: 'account-options/checkbook-info',
    updateCheckbook: 'account-options/update-checkbook',

    // devApiKey: 'WbXqbcwaI4iwLkYpwFoh5EmJXZCfjizavHsfR1gj', // for dev env
    // devApiKey: 'ljRmHtRRCN3PCxOJjMKlr3kEHGiUGCik2k4oTX9O', // for SIT env
    devApiKey: `${APP_API_KEY}`,
    metaDataApiKey: `${METADATA_API_KEY}`,
    uspsApiKey: `${USPS_API_KEY}`,
    loggerApiKey: `${LOGGER_API_KEY}`,
    customerManagementApiKey: `${CUSTOMER_MANAGEMENT_API_KEY}`,
    liveChatApiKey: `${LIVE_CHAT_API_KEY}`,
    accountDefaultNickNameApiKey: `${ACCOUNT_MAP_API_KEY}`,
    verifyCustomerApiKey: `${VERIFY_CUSTOMER_API_KEY}`,

    getTransactionHolder: 'positions',
    getTransactionHolderForManage: 'brokerage/msr/positions',
    getAccountServiceOptions: 'positions/account-option-lookup',

    // Access Form
    getAccessForms: 'document-api/search-document/search?category=accessform',
    getFormsByCategory: 'document-api/search-document/search?category=',

    envVariable: 'LIVE',
    searchDocument: 'document-api/search-document/document-center-search',
    serviceAccount: 'alfrescosecret', // It can change dynamically check with alfresco team
    getDocument: 'document-api/get-document/msr/get',
    getUserDocumentList: 'document-api/search-document/document-center-search-external',
    getTransferDocumentList: 'document/move',

    getDocumentPdfList: 'portfolio/templates/get',
    getDocumentId: 'document/metadata/search',
    updateDocument:'portfolio/template/update',

    // Purchase Form -
    getPurchaseWireTransferInfo: 'transaction/purchase/wireTransfer',

    // Transactions Liquidation
    getFundListForLiquidation: 'transaction/liquidation/fundList',

    // Accounts
    getAccountActivities: 'transaction/history',
    deleteAccountActivity: 'transaction/cancel',

    // Master Registration Accounts
    getMasterRegAccounts: 'transactions/purchase/masterregaccounts',
    getMasterRegAccountsForLiquidate: 'transactions/liquidate/masterregaccounts',
    getExchangeMasterRegAccounts: 'exchange/masterregaccounts',
    updateAccountNickName: 'accounts/nickname',
    updateAccountNickNameForManage: 'brokerage/accounts/nickname',

    // Get fund list
    getFundAccountList: 'transactions/purchase/funds',
    getExistingFundAccountList: 'transactions/liquidate/funds',
    getExchangeFundAccountList: 'exchange/fundList',
    getExchangeInFundAccountList: 'exchange/in',

    // Dashboard
    getAccountSummary: 'accountSummary',
    getPortfolioSummary: 'portfoliosummary',
    getDashboardSavedItemsList: 'application/v1/list',
    dashboardPortfolioSummaryKey: `${DASHBOARD_PORTFOLIO_SUMMARY_API_KEY}`,
    ascensusRedirectionAPIKey: `${ASCENSUS_API_KEY}`,
    getSystemHealth: 'services/msr/healthCheck',
    
    // Single Sign On
    getSmalAssertion: "authorization/generateSAMLAssertion",

    getEnabledFeature:'authorization/msr/getEnabledFeature',

    // getContributionForIRA: 'iraContributions',
    getContributionForIRA: 'validateIRAContributions',
    getContributionForMarketIRA: 'v2/validateIRAContributions',

    validateACHLimit: 'validateACHLimits',
    getRequestEncription: 'encrypt/amt',

    waitTime: 'livechat-api/waittime/CHAT_OTHERS',
    operationalHours: 'livechat-api/operationhours/SCHEDULE1',

    // Live Chat
    msrAvailability: 'livechat-api/availability/',
    getLiveChatSupportHoursOfOperation: 'livechat-api/operationhours/',
    getLiveChatQueueMetrics: 'livechat-api/queue',
    getLiveChatQueueWaitTime: 'livechat-api/waittime/',
    getMsrAvailabilityFromSchedules: 'livechat-api/availability/group/',
    getPhoneQueueWaitTime: 'livechat-api/waittime/',
    getPhoneSupportHoursOfOperation: 'livechat-api/operationhours/',
    liveChatWaitTime: 'livechat-api/waittime/CHAT_OTHERS',
    getCheckDeliveryAddress: 'useraddress',

    // State taxes
    getStateCodeGroup: 'stateTaxGroup',
    getStateTaxesForGroup: 'stateTax',

    // Trading Calendar
    getTradingCalendar: 'trading-calendar?exchange=nyse',

    // New Account Opening
    getFundAccountsListForNAO: 'accounts/open/funds',
    getMarstoneRedirectionData: 'msc/redirectPayload',

    // Saved Applications
    getSavedApplications: 'list',
    saveApplication: 'save',

    // MSR Web Portal
    
    msrUserCustomerProfile: 'user/profile/view',
    msrAPIKey: `${APP_API_KEY}`,
    msrAscensus: 'amt',
    msrAscensusKey: 'P2wuPe7bgS4nWQO2fRYBAapp6aTVHd8M8qj6AxQM',
    msrProfileInfo: 'member/profile',
    getMsrCheckbookInfo: 'checkbook-info',
    msrCheckbookKey: `${APP_API_KEY}`,
    getMsrDividendInfo: 'dividends-info',
    msrDividendKey: `${APP_API_KEY}`,
    getMsrManageBenificiary: 'beneficiaries/master-regs',
    msrManageBenificiaryKey: `${APP_API_KEY}`,
    getMsrBenificiary: 'get-beneficiaries',
    postMsrBeneficiaries:'account-options/msr/get-benefbymasterregid',
    //msrPortfolioSummaryKey: `${APP_API_KEY}`,
    msrPortfolioSummaryKey: `${APP_API_KEY}`,
    msrBasicProfileInfoKey: `${MEMBER_PROFILE_API}`,
    msrProfileAssociation: 'member/association',
    msrTransactionKey: `${APP_API_KEY}`,
    msrTransactionApiKey: `${TRANSACTION_API_KEY}`,
    msrStatementSetting: 'statement-settings',
    msrStatementSettingKey: `${APP_API_KEY}`,
    msrMemberPreference: 'getMemberPreferences',
    msrMemberPreferenceKey: `${MEMBER_PREFERENCES_KEY}`,
    msrSecurityList: 'member/securityquestions/list',
    msrSecurityListKey: `${SECURITY_API_KEY}`,
    msrSWPTransactions: 'swp-transactions',
    msrBankToFundPad: 'rt-bank-to-fund-account-by-pad',
    msrGetSingleSWP: 'get-single-swp',
    msrRMDTransactions: 'get-rmd-transactions',
    msrGetRMDAcc: 'get-rmd-accounts',
    msrSWPAcc: 'swp-accounts',
    // MSR Recurring Transfer Bank to Fund
    msrrtBankToFundAcc: 'rt-bank-to-fund-accounts',
    msrAccountOptionKey: `${ACCOUNT_OPTIONS_API_KEY}`,
    // Manage Account Preference
    getAccountPreference: 'account-options/statement-settings',
    editAccountPreference: 'account-options/statement-settings',

    // Recurring Transfer Bank to Fund
    rtBankToFundForAdd: 'account-options/rt-bank-to-fund-for-add',
    addRTBankToFundAccount: 'account-options/add-rt-bank-to-fund-account',

    // Get account default nick name
    getDefaultAccountNickName: 'referencedata/accountmap/default/lookup',

    // Verify customer information
    validateCustomerInfo: 'verify/customer',
    getCognitoForGIACTVerification: 'oauth2/token',
    // get IRA Contribution year

    getIRAContributionYearValues : 'masterReg',
    getmarketNAOInfo: 'txnstore/msr/brokerage/application/',
    postmarketNAOInfo: 'txnstore/msr/brokerage/application/save',
    savedApplicationDismiss: "dismiss",
    kycVerification: 'verify/kyc',
    genericidLookuplist: 'referencedata/msr/genericid/lookuplist',
    getApplicationConfig: 'referencedata/environment/configurations',
    getUploadSession: 'msr/v1/upload-document/sessionId',
    uploadSMCDocument: 'document-manager/document',
    submitKycUploadedDocuments: 'application/msr/kyc/client/document/submit',
};

export default Config;
